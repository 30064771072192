import { MAINTAIN_PERMISSION, GROWERS_GRADES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Grades",
      "search-by": "CropCode,Code,Description",
      "ph-search-by": "Search by Crop Code, Grade Code, Grade Description"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("div", {
          staticClass: "text-center"
        }, [_vm.hasMaintainPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  }, [_c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Grade Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Grade Description",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Sequence",
    attrs: {
      "data-index": "sequence",
      "title": "Sequence",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "WarningTolerance",
    attrs: {
      "title": "Warning Tolerance",
      "align": "center",
      "data-index": "warningTolerance",
      "render-icon": _vm.renderIcon,
      "width": 150,
      "sorter": true
    }
  }), _c("text-field", {
    key: "WarningToleranceLimit",
    attrs: {
      "data-index": "warningToleranceLimit",
      "title": "Warning Tolerance Limit",
      "sorter": true
    }
  }), _c("text-field", {
    key: "WarningText",
    attrs: {
      "data-index": "warningText",
      "title": "Warning Text",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "ObservedTolerance",
    attrs: {
      "title": "Observed Tolerance",
      "align": "center",
      "data-index": "observedTolerance",
      "render-icon": _vm.renderIcon,
      "width": 150,
      "sorter": true
    }
  }), _c("text-field", {
    key: "ObservedToleranceLimit",
    attrs: {
      "data-index": "observedToleranceLimit",
      "title": "Obs Tol Limit",
      "sorter": true
    }
  }), _c("text-field", {
    key: "AppliedDeduction",
    attrs: {
      "data-index": "appliedDeduction",
      "title": "Applied Deduction",
      "sorter": true
    }
  }), _c("icon-field", {
    key: "DownloadToJag",
    attrs: {
      "title": "Jag to Scale",
      "align": "center",
      "data-index": "downloadToJag",
      "render-icon": _vm.renderIcon,
      "width": 150,
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListGrades",
  inject: ["can"],
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListGrades = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-grade"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.grades",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/grades/create",
      "edit-route": "/watties-grower/grades/:id",
      "page": _vm.page
    }
  }, [_c("list-grades")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Grades",
  components: {
    ListGrades
  },
  data() {
    return {
      ListGrades,
      apiUrl,
      page: GROWERS_GRADES,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "grade",
          title: "Grades",
          path: "/watties-grower/grades"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
